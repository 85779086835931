@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap");

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  background-color: #333;
  padding: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 700px) {
  body {
    padding: 25px 8px;
  }
}
